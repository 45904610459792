import React from 'react';
import { bool, func, node, number } from 'prop-types';
import classNames from 'classnames';

import css from './PopupOpenerButton.module.css';

const PopupOpenerButton = props => {
  const { isSelected, labelMaxWidth, toggleOpen, children, isOpen } = props;

  const labelStyles = isSelected ? css.labelSelected : css.label;
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;

  return (
    <button
      className={classNames(labelStyles, labelMaxWidthStyles)}
      style={labelMaxWidthMaybe}
      onClick={() => toggleOpen()}
    >
      {children}
      <span
        className={classNames(
          {
            [css.upArrow]: isOpen,
          },
          css.downArrow
        )}
      >
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.57805 2.97877L5.62785 7.49047C6.38824 8.16984 7.61657 8.16984 8.37696 7.49047L13.4268 2.97877C14.6551 1.88133 13.7777 0 12.0424 0H1.94286C0.207602 0 -0.650279 1.88133 0.57805 2.97877Z"
            fill="#858C94"
          />
        </svg>
      </span>
    </button>
  );
};

PopupOpenerButton.defaultProps = {
  isSelected: false,
  labelMaxWidth: null,
};

PopupOpenerButton.propTypes = {
  isSelected: bool,
  toggleOpen: func.isRequired,
  children: node.isRequired,
  labelMaxWidth: number,
};

export default PopupOpenerButton;
